import * as React from "react";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const ServiceGrid = ({ gridItems }) => (
  <div className="columns is-multiline">
    {gridItems.map((service) => (
      <div key={service.title} className="column is-4">
        <section className="section service-icon">
          <FontAwesomeIcon icon={service.icon} className="fa-3x" />
          <p className="service-title">{service.title}</p>
        </section>
      </div>
    ))}
  </div>
);

ServiceGrid.propTypes = {
  gridItems: PropTypes.arrayOf(
    PropTypes.shape({
      image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
      text: PropTypes.string,
    })
  ),
};

export default ServiceGrid;
